import Nav from './components/nav/Nav';
import Intro from './pages/intro/Intro';
import Teachers from './pages/teachers/Teachers';
import Steps from './pages/steps/Steps';
import Price from './pages/price/Price';
import Faq from './pages/faq/Faq';
import Reviews from './pages/reviews/Reviews';
import Feedback from './pages/feedback/Feedback';
import Wrapper from './Wrapper';

function App() {
  return (
    <div className="App">
      <Wrapper>
        <Nav />
      </Wrapper>

      <Wrapper isDifferentColor>
        <Intro />
      </Wrapper>

      <Wrapper>
        <Teachers />
      </Wrapper>

      <Wrapper isDifferentColor>
        <Steps />
      </Wrapper>

      <Wrapper>
        <Price />
      </Wrapper>

      <Wrapper isDifferentColor>
        <Faq />
      </Wrapper>

      <Wrapper>
        <Reviews />
      </Wrapper>

      <Wrapper isDifferentColor>
        <Feedback />
      </Wrapper>

    </div>
  );
}

export default App;
