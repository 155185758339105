import './price.css';
import React, { useState } from 'react';
import topPicture from './topPicture.png';
import walrus from './walrus.png';
import heartViolet from '../../assets/heartViolet.png';
// import pictureOfNumber1 from './assets/pictureOfNumber1.png';

function Price() {
  const [isIndividual, setIsIndividual] = useState(true);

  return (
    <div className="prices" id="prices">
      <h2 className="headerSection headerPrices">Стоимость занятий</h2>
      <div className="typeOfLesson">
        <div className="button-group">
          <div
            tabIndex={-1}
            role="button"
            className={isIndividual ? 'selected-button' : ''}
            onClick={() => { setIsIndividual(true); }}
          >
            Персональные
          </div>
          <div
            tabIndex={-1}
            role="button"
            className={!isIndividual ? 'selected-button' : ''}
            onClick={() => { setIsIndividual(false); }}
          >

            Групповые
          </div>
        </div>

        {isIndividual
          ? (
            <div className="packagesPool">

              <div className="sessions">

                <div className="singleSession">
                  <div id="red" className="titleTime">
                    45 минут
                  </div>
                  <div className="nicknamesAll">
                    <div className="nicknameOne">
                      <p>матрос</p>

                      <p className="changeText">10 €</p>

                      <div className="makeRow">
                        <p className="purple">количество:</p>
                        <p className="digit">8</p>
                      </div>

                      <p className="line" />

                      <div className="makeRow">
                        <p className="purple">цена абонемента:</p>
                        <p className="digit">80</p>
                      </div>
                      <a href="#feedback">
                        <button className="buttonIntro">Попробовать</button>
                      </a>
                    </div>
                    <div className="nicknameOne">
                      <p>матрос</p>

                      <p className="changeText">8 €</p>
                      <div className="makeRow">
                        <p className="purple">количество:</p>
                        <p className="digit">16</p>
                      </div>

                      <p className="line" />

                      <div className="makeRow">
                        <p className="purple">цена абонемента:</p>
                        <p className="digit">128</p>
                      </div>
                      <a href="#feedback">
                        <button className="buttonIntro">Попробовать</button>
                      </a>
                    </div>
                    <img className="walrus" src={walrus} alt="walrus" />

                  </div>
                </div>
                <div className="changecollor singleSession">
                  <div className="titleTime">
                    60 минут
                  </div>
                  <div className="nicknamesAll">
                    <div className="nicknameOne">
                      <p>матрос</p>

                      <p className="changeText">15 €</p>
                      <div className="makeRow">
                        <p className="purple">количество:</p>
                        <p className="digit">8</p>
                      </div>

                      <p className="line" />

                      <div className="makeRow">
                        <p className="purple">цена абонемента:</p>
                        <p className="digit">120</p>
                      </div>
                      <a href="#feedback">
                        <button className="buttonIntro">Попробовать</button>
                      </a>
                    </div>
                    <img className="topPicture" src={topPicture} alt="topPicture" />
                    <div className="nicknameOne">
                      <p>матрос</p>

                      <p className="changeText">13 €</p>
                      <p className="specialBenifit">Хит продаж!</p>
                      <div className="makeRow">
                        <p className="purple">количество:</p>
                        <p className="digit">16</p>
                      </div>

                      <p className="line" />

                      <div className="makeRow">
                        <p className="purple">цена абонемента:</p>
                        <p className="digit">208</p>
                      </div>
                      <a href="#feedback">
                        <button className="buttonIntro">Попробовать</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="makeRow spaceBetween" id="groupMain">

              <div className="changecollor singleSession ">
                <div className="titleTime" />
                <div className="nicknamesAll">

                  <div className="nicknameOne">
                    <p>мини-группа</p>

                    <p className="changeText">50 € в месяц</p>
                    <div className="makeRow">
                      <p className="purple">количество:</p>
                      <p className="digit">8</p>
                    </div>

                    <p className="line" />

                    <div className="makeRow">
                      <p className="purple">цена на одного:</p>
                      <p className="digit">8</p>
                    </div>
                    <a href="#feedback">
                      <button className="buttonIntro">Попробовать</button>
                    </a>
                  </div>
                </div>
              </div>
              <div id="pointsForGroup">
                <p>Занятия проходят в мини-группах по 4 человека.</p>
                <p>Оплачивается сразу месяц занятий</p>
                <p>О имеющихся группах уточняйте у администратора</p>
                <img id="heartOnPrice" src={heartViolet} className="heartViolet" alt="heartPic3" />

              </div>
            </div>
          )}

        <div className="priceBen">
          <p>Бесплатный пробный урок</p>
          <p>Бесплатная отмена</p>
          <p>Бесплатная замена преподавателя</p>
        </div>
      </div>

    </div>
  );
}
export default Price;
