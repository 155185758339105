import './reviews.css';
import React, { useState } from 'react';
import Seal from './assets/Seal.png';
import HollowDot from '../../assets/hollowDot.svg';
import FilledDot from '../../assets/filledDot.svg';
import Arrow from '../../assets/arrow.png';
import heartGreen from '../../assets/heartGreen.png';
import heartViolet from '../../assets/heartViolet.png';
import heartRed from '../../assets/heartRed.png';
import CustomCarousel from './Carousel';

const peopleRevies = [
  {
    id: 1,
    nombre: 'Екатерина',
    points:
    'Здравствуйте, спасибо вам за отличные уроки испанского! Занятия интересные, лёгкие, дети занимаются с удовольствием. А я уже спокойно могу поддерживать разговоры с магазинах и кафе.Обязательно продолжим обучение в вашей школе.',

  },
  {
    id: 2,
    nombre: 'Марина',
    points:
    'Занимаюсь в группе с друзьями. Мне очень нравятся уроки испанского!!  Я даже люблю делать домашку😍 !! Мы учим язык , на котором говорят современные испанцы , живой настоящий язык и слэнг . Вот бы в школах так преподавали языки!! Я была бы полиглотом 🤣 Рада, что узнала про idiomar!! Буду советовать.',

  },
  {
    id: 3,
    nombre: 'Игорь',
    points:
    'Очень крутой педагог -Виктория. Говорит быстро на испанском, чтобы не расслаблялся и если что то не понимаю дублирует на русском. Разнопланово-послушали песенку, поболтали, посмотрели картинку, сделали упражнение из учебника на экране. Короче я ввосторге и недорого. ',

  },
  {
    id: 4,
    nombre: 'Георгий',
    points:
        'Виктория - самый лучший педагог. Она говорит на испанском как носитель. ',

  },
];

function Reviews() {
  const [i, setI] = useState(0);
  const rightClick = () => {
    if (i + 1 > peopleRevies.length - 1) {
      setI(0);
    } else {
      setI(i + 1);
    }
  };
  const leftClick = () => {
    if (i <= 0) {
      setI(peopleRevies.length - 1);
    } else {
      setI(i - 1);
    }
  };
  return (
    <div className="reviews">
      <div className="header-reviews">
        <h2 className="headerSection"> Отзывы </h2>
      </div>
      <CustomCarousel objects={peopleRevies} />
      <div className="content-reviews">
        <div className="arrow_and_text">
          <img src={Arrow} alt="left click" id="leftarrow" onClick={leftClick} />
          <div className="reviews-cards">
            <h3>{peopleRevies[i].nombre}</h3>
            <p>{peopleRevies[i].points}</p>
          </div>
          <img src={Arrow} alt="right click" id="rightarrow" onClick={rightClick} />
          <div className="">
            {peopleRevies.map(
              (t, index) => {
                i === index ? (<img src={FilledDot} />)
                  : (<img src={HollowDot} />);
              },
            )}
            {' '}

          </div>
        </div>
        <div className="pictures">
          <div className="hearts">
            <img src={heartRed} className="reviews-heart-red" alt="heartPic" />
            <img src={heartViolet} className="reviews-heart-violet" alt="heartPic3" />
            <img src={heartGreen} className="reviews-heart-green" alt="heartPic2" />
          </div>

          <img className="seal" src={Seal} />
        </div>
      </div>
    </div>
  );
}
export default Reviews;
