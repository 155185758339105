import house from './assets/house.png';
import ship from './assets/ship.svg';
import wheel from './assets/wheel.png';
import gold from './assets/gold.png';
import './intro.css';

function Intro() {
  return (
    <div className="intro" id="intro">
      <div className="description">
        <div className="picture">
          <img className="schoolMainPic" src={house} alt="schoolPictire" />
        </div>
        <div className="about">
          <div className="header">
            <h2 className="aboutSchoolIntro">О школе </h2>
          </div>
          <div className="paragraph">
            <div className="schoolDescr">
              <p>

                Учите
                {' '}
                <b>испанский язык онлайн</b>
                {' '}
                с лучшими преподавателями
                по удобному расписанию
                не выходя из дома
              </p>
            </div>
          </div>
          <div className="hide-mobile">
            <a href="#feedback">
              <button className="buttonIntro">Попробовать</button>
            </a>
          </div>
        </div>
      </div>
      <div className="hide-desktop">
        <a href="#feedback">
          <button className="buttonIntro">Попробовать</button>
        </a>
      </div>
      <div className="benefits">
        <div>
          <div>
            <img src={ship} alt="Picture of ship" />
          </div>
          <div>
            <p>
              Первое пробное занятие
              <br />
              бесплатно
            </p>
          </div>
        </div>
        <div>
          <div>
            <img src={wheel} alt="Picture of wheel" />
          </div>
          <div>
            <p>
              {' '}
              занятия подходят и
              <br />
              взрослым и детям
            </p>
          </div>
        </div>
        <div>
          <div>
            <img src={gold} alt="Picture of gold" />
          </div>
          <div>
            <p>
              стоимость занятий
              <br />
              от 5 евро
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Intro;
