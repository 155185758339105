import './teachers.css';
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Teacher from './assets/Teachers.png';
import MiFoto from './assets/miFoto.jpg';
import Arrow from '../../assets/arrow.png';
import Irina from './assets/irina.png';
import HollowDot from '../../assets/hollowDot.svg';
import FilledDot from '../../assets/filledDot.svg';
import CustomCarousel from './Carousel';

const teachersCards = [
  {
    id: 1,
    foto: MiFoto,
    nombre: 'Виктория',
    points: [
      'Проживает в Испании  23 года',
      'Диплом DELE , уровень испанкского С1',
      'Ежегодно проходит курсы и семинары для преподавателей',
    ],
  },
  {
    id: 2,
    foto: Irina,
    nombre: 'Ирина',
    points: [
      'Проживает в Испании с 2003 года ',
      'Проводила уроки испанского в Московских школах',
      'Более 25 лет репетиторской работы - очно и онлайн',
    ],
  },
];
function Teachers() {
  const [i, setI] = useState(0);
  const rightClick = () => {
    if (i + 1 > teachersCards.length - 1) {
      setI(0);
    } else {
      setI(i + 1);
    }
  };
  const leftClick = () => {
    if (i <= 0) {
      setI(teachersCards.length - 1);
    } else {
      setI(i - 1);
    }
  };
  return (
    <div className="teachers" id="teachers">
      <div className="text-content">
        <h2 className="aboutSchool headerSection">
          Наша команда
          преподавателей
          {' '}

        </h2>
        <p className="teachers-team">
          Наша команда состоит из сертифицированных специалистов
          и профессионалов своего дела.

        </p>
        <div className="">
          <CustomCarousel objects={teachersCards} />
          <div className="scroll-dots">
            {teachersCards.map(
              (t, index) => { index; },
            )}
          </div>
        </div>
        <img className="animals" alt="decoration" src={Teacher} />

      </div>
      <div className="teachers-cards">
        <img src={Arrow} id="leftarrow" onClick={leftClick} />
        <div className="photo-content">
          <div className="photo-card">
            <p>{teachersCards[i].nombre}</p>
            <img src={teachersCards[i].foto} />
          </div>
          <ul className="descrip">
            {teachersCards[i].points.map(
              (point, index) => <li key={index}>{point}</li>,
            )}

          </ul>

        </div>
        <img id="rightarrow" src={Arrow} onClick={rightClick} />
        <div className="">
          {teachersCards.map(
            (t, index) => {
              i === index ? (<img key={index} src={FilledDot} />)
                : (<img key={index} src={HollowDot} />);
            },

          )}
        </div>
      </div>
    </div>

  );
}
export default Teachers;
