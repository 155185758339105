import { Carousel } from 'react-responsive-carousel';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carousel.css';

function CustomCarousel({ objects }) {
  return (
    <div className="reviews-cards review-carousel">
      <Carousel
        showStatus={false}
        showThumbs={false}
      >
        {objects.map((item) => (
          <div key={item.id}>
            <h3 className="review-nombre">{item.nombre}</h3>
            <p className="review-points">{item.points}</p>

          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default CustomCarousel;
