import React, { useState } from 'react';

import Picture from './assets/topPicture.png';
import Accordion from './Accordion';
import './faq.css';

function Faq() {
  const [selectedAccordion, setSelectedAccordion] = useState(0);

  const changeAccordion = (accordionNumber) => {
    if (selectedAccordion === accordionNumber) {
      setSelectedAccordion(0);
    } else {
      setSelectedAccordion(accordionNumber);
    }
  };

  return (
    <div className="faq" id="faq">
      <div className="faq-header-wrapper">
        <h2 className="headerSection">Часто задаваемые вопросы</h2>
      </div>
      <div className="faq-content">
        <img className="faq-img" src={Picture} alt="" />
        <div className="faq-questions">
          <Accordion
            id={1}
            changeAccordion={changeAccordion}
            selectedAccordion={selectedAccordion}
            question1="Онлайн обучение также эффективно,
как и оффлайн?"
            answer1="Конечно! Обучение в онлайн не только
позволяет изучать язык когда и где угодно,
но и быть в постоянной связи с
преподавателем и не тратить время
на дорогу от дома до школы."
          />
          <Accordion
            id={2}
            changeAccordion={changeAccordion}
            selectedAccordion={selectedAccordion}
            question1="Можно ли начать обучение бесплатно?"
            answer1="В нашей школе у вас есть отличная
 возможность попробовать обучение
 и проверить уровень вашего
 испанского на пробном бесплатном занятии!
 "
          />
          <Accordion
            id={3}
            changeAccordion={changeAccordion}
            selectedAccordion={selectedAccordion}
            question1={(
              <>
                Как отменить или перенести занятие,
                <br />
                если оно уже оплачено?
              </>
    )}
            answer1="Перенести занятие можно написав нашему менеджеру. Удобное для вас расписание можно согласовать с преподавателем на пробном занятии или же менеджером вашей группы."
          />
          <Accordion
            id={4}
            changeAccordion={changeAccordion}
            selectedAccordion={selectedAccordion}
            question1="Обязательно ли иметь компьютер
 для занятий?"
            answer1="Нет. Заниматься можно с планшета или
 с телефона."
          />
          <Accordion
            id={5}
            changeAccordion={changeAccordion}
            selectedAccordion={selectedAccordion}
            question1="Подходят ли онлайн занятия детям?"
            answer1="Да. Все новые слова и фразы сопровождаюются
визуально, каждое слово преподавателей
сопровождается жестами и мимикой."
          />
        </div>
      </div>
    </div>
  );
}
export default Faq;
