import React from 'react';

export default function Wrapper({ children, isDifferentColor }) {
  return (
    <div className={`global-wrapper ${isDifferentColor ? 'global-wrapper-colored' : ''}`}>
      <div className="global-wrapper-content">
        {children}
      </div>
    </div>
  );
}
