import './steps.css';
import pictureOfNumber1 from './assets/pictureOfNumber1.png';
import pictureOfNumber2 from './assets/pictureOfNumber2.png';
import pictureOfNumber3 from './assets/pictureOfNumber3.png';
import pictureOfNumber4 from './assets/pictureOfNumber4.png';
import pictureOfNumber5 from './assets/pictureOfNumber5.png';
import heartRed from '../../assets/heartRed.png';
import heartGreen from '../../assets/heartGreen.png';
import heartViolet from '../../assets/heartViolet.png';

function EtapuVstuplenia() {
  return (
    <div className="etapu">
      <div className="etapyLeft">
        <h2 className="headerSection">
          Этапы вступления в клуб
        </h2>
        <div className="stages">
          <div>
            <img src={pictureOfNumber1} alt="picOfNum" />
            <p>Оставьте заявку на пробное бесплатное занятие</p>
          </div>
          <div>
            <img src={pictureOfNumber2} alt="picOfNum" />
            <p className="paragrath">Методист свяжется с Вами и предложит удобное время и преподавателя </p>
          </div>
          <div>
              <img src={pictureOfNumber3} alt="picOfNum" />
            <p>Проведем 30-минутное занятие </p>
          </div>
          <div>
              <img src={pictureOfNumber4} alt="picOfNum" /> 
            <p>По итогу подберем программу обучения под уровень и цели</p>
          </div>
            <div>
              <img src={pictureOfNumber5} alt="picOfNum" />
            <p>Выбираем удобное расписание и с удовольствием учим язык</p>
          </div>
        </div>
      </div>
      <div className="stepsRight">
        <img src={heartRed} className="heartRed" alt="heartPic" />
        <img src={heartGreen} className="heartGreen" alt="heartPic2" />
        <img src={heartViolet} className="heartViolet" alt="heartPic3" />
      </div>
    </div>
  );
}
export default EtapuVstuplenia;
