import logo from './lg.png';
import './nav.css';
import './burger.css';
import { slide as Menu } from 'react-burger-menu';
import { useState } from 'react';

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  return (

    <div className="nav">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="buttoms2">
        <a href="#intro">О нас</a>
        <a href="#teachers">Преподаватели</a>
        <a href="#prices">Стоимость</a>
        <a href="#faq">Вопросы</a>
        <a href="#feedback">Отзывы</a>
      </div>
      <div className="burger-menu">
        <Menu isOpen={isOpen} right onStateChange={(state) => setIsOpen(state.isOpen)}>
          <a onClick={() => setIsOpen(false)} href="#intro">О нас</a>
          <a onClick={() => setIsOpen(false)} href="#teachers">Преподаватели</a>
          <a onClick={() => setIsOpen(false)} href="#prices">Стоимость</a>
          <a onClick={() => setIsOpen(false)} href="#faq">Вопросы</a>
          <a onClick={() => setIsOpen(false)} href="#feedback">Отзывы</a>
        </Menu>
      </div>
    </div>

  );
}
export default Nav;
