import { Carousel } from 'react-responsive-carousel';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carousel.css';

function CustomCarousel({ objects }) {
  return (
    <div className="carousel-root">
      <Carousel
        showStatus={false}
        showThumbs={false}
      >
        {objects.map((item) => (
          <div key={item.id}>
            <img src={item.foto} alt="" />
            <p className="legend">{item.nombre}</p>
            <ul className="descrip">
              {item.points.map(
                (point, index) => <li key={index}>{point}</li>,
              )}

            </ul>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default CustomCarousel;
