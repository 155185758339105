import React from 'react'
import Arrow from '../../assets/arrow.png';

export default function Accordion({id, changeAccordion, selectedAccordion, question1, answer1}) {
  return (
    <div className="accordion">
    <div className="fixed-accordion-section">
      <h5 className="accordion-header">{question1}</h5>
      <img
        onClick={() => {changeAccordion(id)}}
        src={Arrow}
        alt="toggle icon"
        className={
          `faq-toggle-icon ${selectedAccordion===id&&"accordion-arrow-toggled"}`
        }
      />
    </div>
    <div className={`toggle-accordion-section ${selectedAccordion === id && 'accordion-grow'}`}>
      <p className="accordionAnswer">
      {answer1}
      </p>
    </div>
  </div>
  )
}
