import './feedback.css';
import React, { useState } from 'react';
import heartGreen from '../../assets/heartGreen.png';
import heartViolet from '../../assets/heartViolet.png';

function Feedback() {
  const [text, setText] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  function filterText(value) {
    return value.replace(/[^0-9]/g, '');
  }
  function filterNumber(value) {
    return value.replace(/[^A-Za-zA-Яа-яЁё]/g, '');
  }

  function handlePhoneInput(event) {
    setNumber(filterText(event.target.value));
  }
  function handleNameInput(event) {
    setName(filterNumber(event.target.value));
  }
  function handleTextInput(event) {
    setText(event.target.value);
  }
  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    const data = {
      name, phone_number: number, text,
    };
    console.log(data);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify(data);
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch('https://idiomar.pythonanywhere.com/request/', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.result === 'success') {
          setIsSuccess(true);
          setNumber('');
          setName('');
          setText('');
        } else {
          setIsError(true);
        }
        console.log(result.result);
      })
      .catch((error) => {
        console.log('error', error);
        setIsError(true);
      });
    setIsLoading(false);
  }

  return (
    <div className="feedback" id="feedback">
      <div className="header-feedback">
        <h2 className="headerSection"> Обратная связь </h2>
      </div>
      <div className="content-feedback">
        <img src={heartViolet} className="feedback-heart-blue" alt="heartPic3" />
        <form className="userdata" onSubmit={handleSubmit}>
          <h3>запишитесь на пробный бесплатный урок</h3>
          {isSuccess ? <p className="success">Запрос отправлен!</p> : null}
          {isError ? <p className="error">Ошибка, попробуйте снова</p> : null}
          <input value={name} placeholder=" Имя" onChange={handleNameInput} />
          <input value={number} placeholder=" Телефон" onChange={handlePhoneInput} />
          <textarea value={text} className="comments" onChange={handleTextInput} placeholder=" Комментарий (не обязательно)" />
          {isLoading ? <div className="loader" /> : <button type="submit" className="buttonIntro">Попробовать</button>}
        </form>
        <img src={heartGreen} className="feedback-heart-green" alt="heartPic2" />
      </div>
    </div>
  );
}

export default Feedback;
